import React from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';

// HOC for antd modal to be used across EVO
// Build as part of Antd modal upgrade from v4 to v5
// JIRA TEC-115

const StyledModal = styled(Modal)`
  font-family: 'Roboto' !important;
  .ant-modal-content {
    padding: 0;
  }
  .ant-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
    inset-inline-end: 54px;
    border-radius: 0px;
    height: 0px;
    width: 0px;
  }
  .ant-modal-close-x {
    display: block;
    width: 54px;
    height: 54px;
    font-size: 2.5rem;
    font-style: normal;
    line-height: 54px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-mask {
    background-color: rgba(34, 52, 69, 0.45) !important;
  }
  .ant-modal-header {
    padding: 30px 30px 0;
    margin-bottom: 0px;
  }
  .ant-modal-footer {
    background-color: var(--catskill-white);
    margin-top: 0px;
    padding: 16px 30px;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const ModalV5 = (props: any) => {
  return <StyledModal {...props}>{props.children}</StyledModal>;
};

export default ModalV5;
