import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

const Button = styled(AntButton)`
  font-family: 'Roboto' !important;
  background: var(--blue);
  border-radius: var(--border-radius-large);
  color: var(--white);
  padding: 9px 20px;
  height: 42px;
  font-weight: 500;
  &:hover,
  &:active,
  &:focus {
    background: var(--blue) !important;
    box-shadow: 0px 1px 7px rgba(0, 146, 239, 0.35) !important;
    color: var(--white) !important;
  }
  &:disabled,
  &:disabled:hover {
    background: var(--blue) !important;
    opacity: 0.5 !important;
    color: var(--white) !important;
  }
  ${(props) =>
    props.itemProp &&
    css`
      color: var(--black);
      background: linear-gradient(
        180deg,
        var(--white) 0%,
        var(--lighter-gray) 100%
      );
      border: 1px solid #c4cdd5;
      font-weight: normal;
      &:hover,
      &:focus {
        color: var(--black) !important;
        background: linear-gradient(
          180deg,
          var(--white) 0%,
          var(--lighter-gray) 100%
        ) !important;
        box-shadow: 0px 2px 6px rgba(43, 93, 189, 0.25) !important;
        border: 1px solid #c4cdd5 !important;
      }

      &:disabled,
      &:disabled:hover {
        color: var(--gray) !important;
        background: linear-gradient(
          180deg,
          var(--white) 0%,
          var(--lighter-gray) 100%
        ) !important;
      }
    `}
`;

export { Button };
